<template>
  <b-row
    class="match-height h-100"
    style="min-height: 100vh;"
  >
    <b-col cols="12">
      <b-card>
        <b-tabs
          active
          fill
        >
          <b-tab>
            <template #title>
              <feather-icon icon="SmartphoneIcon" />
              <span>Cihazlarım</span>
            </template>
            <yuz-tanima-kayit
              :items="cihazlar"
              :yukleniyor="yukleniyor"
              :fields="fields"
              :sistemler="sistemler"
              @tabloGetir="tabloGetir"
            />
          </b-tab>
        </b-tabs>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BTab, BTabs, BCard,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import yuzTanimaKayit from '@/views/yuztanima/yuzTanimaKayit.vue'

export default {
  name: 'YuzTanimaVue',
  components: {
    yuzTanimaKayit,
    BRow,
    BCol,
    BTab,
    BTabs,
    BCard,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      axios,
      cihazlar: [],
      sistemler: [],
      sistem: [],
      gruplar: [],
      fields: [{ key: 'tf_cihazAdi', label: 'Cihaz Adı' }, { key: 'tf_cihazId', label: 'Cihaz Id' }, { key: 'tf_ip', label: 'Cihaz Ip' }, { key: 'tf_port', label: 'Cihaz Port' }, { key: 'actions', label: '' }],
      yukleniyor: false,
    }
  },
  mounted() {
    this.tabloGetir()
    // this.grupGetir()
  },
  methods: {
    tabloGetir() {
      this.yukleniyor = true
      const payload = {
        sisId: 1,
        kullaniciId: '',
      }
      this.axios.post(`${this.$store.state.kantin.ytUrl}tenfeigetadmin`, payload)
        .then(resp => {
          this.cihazlar = resp.data
          this.yukleniyor = false
        })
        .catch(() => {
          this.yukleniyor = false
        })
      this.axios.post(`${this.$store.state.kantin.ytUrl}sistemget`, payload)
        .then(resp => {
          this.sistemler = resp.data
          this.yukleniyor = false
        })
        .catch(() => {
          this.yukleniyor = false
        })
      this.axios.post(`${this.$store.state.kantin.ytUrl}sistemurl`, payload)
        .then(resp => {
          this.sistem = resp.data
          this.yukleniyor = false
        })
        .catch(() => {
          this.yukleniyor = false
        })
    },
  },
}
</script>
