<template>
  <b-row>
    <b-col cols="12">
      <b-row>
        <b-col
          cols="6"
          class="text-left"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-modal.cihazKaydet
            variant="primary"
            size="sm"
            class="mb-2"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-50"
            />
            <span class="align-middle">Yeni Cihaz Ekle</span>
          </b-button>
        </b-col>
        <b-col
          cols="6"
          class="text-right"
        >
          <b-dropdown
            v-if="items.length !== 0"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="mr-2"
            size="sm"
            text="Kapı Aç"
            variant="danger"
          >
            <b-dropdown-item @click="cihazKapiAc(items)">
              Bütün Cihazlar
            </b-dropdown-item>
            <b-dropdown-divider />
            <b-dropdown-item
              v-for="(tr,indextr) in items"
              :key="indextr"
              @click="cihazKapiAc([items[indextr]])"
            >{{ items[indextr].tf_cihazAdi }}</b-dropdown-item>

          </b-dropdown>
          <b-dropdown
            v-if="items.length !== 0"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            size="sm"
            text="Kapı Kapat"
            variant="success"
          >
            <b-dropdown-item @click="cihazKapiKapat(items)">
              Bütün Cihazlar
            </b-dropdown-item>
            <b-dropdown-divider />
            <b-dropdown-item
              v-for="(tr,indextr) in items"
              :key="indextr"
              @click="cihazKapiKapat([items[indextr]])"
            >{{ items[indextr].tf_cihazAdi }}</b-dropdown-item>

          </b-dropdown>
        </b-col>
      </b-row>
    </b-col>
    <b-table
      :bordered="true"
      :outlined="true"
      :small="true"
      :hover="true"
      :fixed="true"
      :items="items"
      :fields="fields"
      :busy="yukleniyor"
    >
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle" />
          <strong>Yükleniyor...</strong>
        </div>
      </template>
      <template #cell(actions)="row">
        <b-button
          size="sm"
          class="mr-2"
          variant="success"
          @click="duzenleCihaz(row)"
        >
          Düzenle
        </b-button>
        <b-button
          size="sm"
          class="mr-2"
          variant="warning"
          @click="cihazAyarlari(row)"
        >
          Detay
        </b-button>
        <b-button
          size="sm"
          class="mr-2"
          variant="danger"
          @click="cihazSil(row)"
        >
          Sil
        </b-button>
      </template>
    </b-table>
    <b-modal
      id="cihazKaydet"
      ref="cihazKaydet"
      cancel-variant="outline-secondary"
      centered
      size="lg"
      title="Cihaz Ekle / Düzenle"
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
      @hide="sifirla"
    >
      <b-row>
        <b-col cols="12">
          <label>Cihaz Türü</label>
          <v-select
            id="cihazSelect"
            v-model="cihazSecili"
            :options="cihaz"
            :clearable="false"
          >
            <template #no-options="">
              Aradığınız cihaz bulunmamaktadır.
            </template>
          </v-select>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <label>Cihaz Kullanım Tipi</label>
          <v-select
            id="ckSelect"
            v-model="ckSecili"
            :options="ck"
            :clearable="false"
          />
        </b-col>
      </b-row>
      <template v-if="cihazSecili.value === 1">
        <b-row
          class="mt-2"
        >
          <b-col cols="4">
            <b-form-group
              label="Cihaz Ip"
              label-for="cihazIp"
            >
              <b-form-input
                id="cihazIp"
                v-model="cihazIp"
              />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="Cihaz Port"
              label-for="cihazPort"
            >
              <b-form-input
                id="cihazPort"
                v-model="cihazPort"
              />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="Cihaz Şifre"
              label-for="cihazSifre"
            >
              <b-form-input
                id="cihazSifre"
                v-model="cihazSifre"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            class="text-right"
          >
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="primary"
              size="sm"
              class="mb-2"
              :disabled="test"
              @click="tenfeiTest"
            >
              <template v-if="!test">
                <feather-icon
                  icon="RefreshCcwIcon"
                  class="mr-50"
                />
                <span class="align-middle">Test Et</span>
              </template>
              <template v-if="test">
                <b-spinner small />
                Test Ediliyor...
              </template>
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <label>Sistem</label>
            <v-select
              id="sistemSelect"
              v-model="sistemSecili"
              :options="sistemler"
              :clearable="false"
            >
              <template #no-options="">
                Aradığınız sistem bulunmamaktadır.
              </template>
            </v-select>
          </b-col>
        </b-row>
        <b-row
          v-if="degisken"
          class="mt-2"
        >
          <b-col cols="6">
            <b-input-group
              prepend="Cihaz Id"
            >
              <b-form-input
                v-model="cihazId"
                :disabled="true"
              />
            </b-input-group>
          </b-col>
          <b-col cols="6">
            <b-input-group prepend="Cihaz Adı">
              <b-form-input v-model="cihazAdi" />
            </b-input-group>
          </b-col>
          <b-col
            cols="12"
            class="text-right mt-2"
          >
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="primary"
              size="sm"
              class="mb-2"
              :disabled="kayit"
              @click="tenfeiKayit"
            >
              <template v-if="!kayit">
                <feather-icon
                  icon="RefreshCcwIcon"
                  class="mr-50"
                />
                <span class="align-middle">Kaydet</span>
              </template>
              <template v-if="kayit">
                <b-spinner small />
                Kaydediliyor...
              </template>
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>
    <b-modal
      id="cihazAyarlari"
      ref="cihazAyarlari"
      cancel-variant="outline-secondary"
      centered
      size="lg"
      title="Cihaz Ayarları"
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
      @hide="sifirla"
    >
      <b-overlay
        :show="cihazAyarla"
        blur="2px"
        opacity="0.73"
      >
        <h5>Cihaz Ayarları</h5>
        <b-row>
          <b-col cols="4">
            <b-form-group
              label="Cihaz Adı"
              label-for="chAdi"
            >
              <b-form-input
                id="chAdi"
                v-model="chAdi"
              />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="Ekran Koruyucu (saniye)"
              label-for="ekSaniye"
            >
              <b-form-input
                id="ekSaniye"
                v-model="ekSaniye"
              />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="Uyuma (dakika)"
              label-for="uyDakika"
            >
              <b-form-input
                id="uyDakika"
                v-model="uyDakika"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <h5>Bağlantı Ayarları</h5>
        <b-row>
          <b-col cols="4">
            <b-form-group
              label="WAN Ip"
              label-for="wanIp"
            >
              <b-form-input
                id="wanIp"
                v-model="wanIp"
              />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="WAN Port"
              label-for="wanPort"
            >
              <b-form-input
                id="wanPort"
                v-model="wanPort"
              />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="WAN Gönderim Aralığı"
              label-for="wanAralik"
            >
              <b-form-input
                id="wanAralik"
                v-model="wanAralik"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <b-form-group
              label="Push Bildirim Ip"
              label-for="pushIp"
            >
              <b-form-input
                id="pushIp"
                v-model="pushIp"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Push Bildirim Port"
              label-for="pushPort"
            >
              <b-form-input
                id="pushPort"
                v-model="pushPort"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <h5>Biyometrik Ayar</h5>
        <b-row>
          <b-col
            cols="6"
          >
            <label>Tanıma Mesafesi</label>
            <v-select
              id="mesafeSelect"
              v-model="mesafeSecili"
              :options="mesafe"
              :clearable="false"
            >
              <template #no-options="">
                Aradığınız cihaz bulunmamaktadır.
              </template>
            </v-select>
          </b-col>
          <b-col cols="6">
            <label>Canlı Algılama</label>
            <v-select
              id="canliSelect"
              v-model="canliSecili"
              :options="canli"
              :clearable="false"
            >
              <template #no-options="">
                Aradığınız cihaz bulunmamaktadır.
              </template>
            </v-select>
          </b-col>
          <b-col cols="12">
            <label>Tanıma Mesafesi Hassasiyeti</label>
            <vue-slider
              v-model="tanimaHassasiyeti"
              :min="10"
              :max="80"
              :interval="1"
              direction="ltr"
            />
          </b-col>
          <b-col cols="12">
            <label>Canlı Algılama Hassasiyeti</label>
            <vue-slider
              v-model="canliAlgilamaHassasiyeti"
              :min="10"
              :max="80"
              :interval="1"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            class="text-right"
          >
            <b-button
              size="sm"
              class="mr-2"
              variant="danger"
              @click="cihazAyariKaydet"
            >
              Güncelle
            </b-button>
          </b-col>
        </b-row>
      </b-overlay>
    </b-modal>
  </b-row>
</template>

<script>
import axios from 'axios'
import {
  BRow, BCol, BTable, BSpinner, BButton, BModal, BFormGroup, BFormInput, BInputGroup, BOverlay,
  BDropdown, BDropdownItem, BDropdownDivider,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import VueSlider from 'vue-slider-component'

export default {
  name: 'YuzTanimaVue',
  components: {
    BRow,
    BCol,
    BTable,
    BSpinner,
    BButton,
    BModal,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BOverlay,
    VueSlider,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    items: { type: Array, default: () => [] },
    sistemler: { type: Array, default: () => [] },
    fields: { type: Array, default: () => [] },
    yukleniyor: { type: Boolean, default: () => false },
  },
  data() {
    return {
      axios,
      test: false,
      cihaz: [{ label: 'Kantinim Yüz Okuma', value: 1 }],
      cihazSecili: { label: 'Kantinim Yüz Okuma', value: 1 },
      ck: [{ label: 'Giriş - Çıkış', value: 0 }, { label: 'Yemekhane', value: 1 }, { label: 'Yüztanıma', value: 3 }],
      ckSecili: { label: 'Giriş - Çıkış', value: 0 },
      cihazSId: 0,
      cihazIp: '',
      cihazPort: '',
      cihazSifre: '',
      cihazId: '',
      cihazAdi: '',
      degisken: false,
      kayit: false,
      cihazAyarla: false,
      wanIp: '',
      wanPort: '',
      wanAralik: '',
      pushIp: '',
      pushPort: '',
      mesafe: [{ label: 'Yakın', value: 'near' }, { label: 'Orta', value: 'mid' }, { label: 'Uzak', value: 'far' }],
      mesafeSecili: null,
      canli: [{ label: 'Hayır', value: 'no' }, { label: 'Orta Seviye', value: 'yes' }, { label: 'Yüksek Seviye', value: 'double' }],
      canliSecili: null,
      tanimaHassasiyeti: 10,
      canliAlgilamaHassasiyeti: 10,
      ekSaniye: '',
      uyDakika: '',
      chAdi: '',
      sistemSecili: null,
    }
  },
  methods: {
    cihazSil(veri) {
      this.axios.post(`${this.$store.state.kantin.ytUrl}tenfeiremovedevice`, { id: veri.item.tf_id })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Sonuç',
              icon: 'BellIcon',
              text: 'Başarılı',
              variant: 'success',
            },
          })
          this.$emit('tabloGetir')
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Sonuç',
              icon: 'BellIcon',
              text: 'Bir Hata Oluştu',
              variant: 'danger',
            },
          })
        })
    },
    cihazKapiAc(veri) {
      let sayac = 0
      veri.forEach(ele => {
        this.axios.post(`${this.$store.state.kantin.ytUrl}tenfeidevicedoor`, {
          ip: ele.tf_ip, port: ele.tf_port, sifre: ele.tf_sifre, durum: 'open',
        })
          .then(resp => {
            sayac++
            if (resp.data.result_code !== 0) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata',
                  icon: 'BellIcon',
                  text: `${ele.tf_cihazAdi} cihazına erişilemiyor!`,
                  variant: 'danger',
                },
              })
            }
            if (veri.length === sayac) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Sonuç',
                  icon: 'BellIcon',
                  text: 'Başarılı',
                  variant: 'success',
                },
              })
            }
          })
      })
    },
    cihazKapiKapat(veri) {
      let sayac = 0
      veri.forEach(ele => {
        this.axios.post(`${this.$store.state.kantin.ytUrl}tenfeidevicedoor`, {
          ip: ele.tf_ip, port: ele.tf_port, sifre: ele.tf_sifre, durum: 'close',
        })
          .then(resp => {
            sayac++
            if (resp.data.result_code !== 0) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata',
                  icon: 'BellIcon',
                  text: `${ele.tf_cihazAdi} cihazına erişilemiyor!`,
                  variant: 'danger',
                },
              })
            }
            if (veri.length === sayac) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Sonuç',
                  icon: 'BellIcon',
                  text: 'Başarılı',
                  variant: 'success',
                },
              })
            }
          })
      })
    },
    cihazAyariKaydet() {
      const payload = { ip: this.cihazIp, port: this.cihazPort, sifre: this.cihazSifre }
      const veri = {}
      veri.serverHost = this.wanIp.toString()
      veri.serverPort = this.wanPort.toString()
      veri.interval = this.wanAralik.toString()
      veri.pushServerHost = this.pushIp.toString()
      veri.pushServerPort = this.pushPort.toString()
      veri.distance = this.mesafeSecili.value.toString()
      veri.living = this.canliSecili.value.toString()
      veri.matchThreshold = this.tanimaHassasiyeti.toString()
      veri.liveThreshold = this.canliAlgilamaHassasiyeti.toString()
      veri.devName = this.chAdi.toString()
      veri.screensaversTime = this.ekSaniye.toString()
      veri.sleepTime = this.uyDakika.toString()
      payload.veri = veri
      this.axios.post(`${this.$store.state.kantin.ytUrl}tenfeideviceupdate`, payload)
        .then(resp => {
          if (resp.data.result_code === 0) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Durum',
                icon: 'BellIcon',
                text: 'Başarılı.',
                variant: 'success',
              },
            })
          }
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Uyarı',
              icon: 'BellIcon',
              text: 'Bir hata oluştu. Lütfen tekrar deneyiniz.',
              variant: 'danger',
            },
          })
        })
    },
    cihazAyarlari(gVeri) {
      this.$refs.cihazAyarlari.show()
      this.cihazAyarla = true
      this.cihazIp = gVeri.item.tf_ip
      this.cihazPort = gVeri.item.tf_port
      this.cihazSifre = gVeri.item.tf_sifre
      const payload = { ip: gVeri.item.tf_ip, port: gVeri.item.tf_port, sifre: gVeri.item.tf_sifre }
      this.axios.post(`${this.$store.state.kantin.ytUrl}tenfeidevicesettings`, payload)
        .then(resp => {
          if (resp.data.result_code === -1) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Uyarı',
                icon: 'BellIcon',
                text: resp.data.reason,
                variant: 'danger',
              },
            })
            this.$refs.cihazAyarlari.hide()
          } else {
            const veri = resp.data.result_data
            this.wanIp = veri.serverHost
            this.wanPort = veri.serverPort
            this.wanAralik = veri.interval
            this.pushIp = veri.pushServerHost
            this.pushPort = veri.pushServerPort
            // eslint-disable-next-line prefer-destructuring
            this.mesafeSecili = this.mesafe.filter(ele => ele.value === veri.distance)[0]
            // eslint-disable-next-line prefer-destructuring
            this.canliSecili = this.canli.filter(ele => ele.value === veri.living)[0]
            this.tanimaHassasiyeti = veri.matchThreshold
            this.canliAlgilamaHassasiyeti = veri.liveThreshold
            this.chAdi = veri.devName
            this.ekSaniye = parseInt(veri.screensaversTime, 0)
            this.uyDakika = parseInt(veri.sleepTime, 0)
          }
          this.cihazAyarla = false
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Uyarı',
              icon: 'BellIcon',
              text: 'Bir hata oluştu. Lütfen tekrar deneyiniz.',
              variant: 'danger',
            },
          })
          this.$refs.cihazAyarlari.hide()
          this.cihazAyarla = false
        })
    },
    sifirla() {
      this.cihazIp = ''
      this.cihazId = ''
      this.cihazSifre = ''
      this.cihazPort = ''
      this.cihazAdi = ''
      this.sistemSecili = null
      this.cihazSId = 0
      this.degisken = false
    },
    duzenleCihaz(veriler) {
      const veri = veriler.item
      this.degisken = true
      switch (veri.tabloAdi) {
        case 'tenfei':
          this.cihazPort = veri.tf_port
          this.cihazIp = veri.tf_ip
          this.cihazAdi = veri.tf_cihazAdi
          this.cihazSifre = veri.tf_sifre === 0 ? '' : veri.tf_sifre
          this.cihazId = veri.tf_cihazId
          this.cihazSecili = this.cihaz[veri.tabloId]
          this.cihazSId = veri.tf_id
          // eslint-disable-next-line prefer-destructuring
          this.sistemSecili = this.sistemler.filter(ele => ele.value === veri.tf_sisId)[0]
          // eslint-disable-next-line prefer-destructuring
          this.ckSecili = this.ck.filter(ele => ele.value === veri.tf_cihazTipi)[0]
          break
        default:
      }
      this.$refs.cihazKaydet.show()
    },
    tenfeiTest() {
      this.test = true
      const payload = { ip: this.cihazIp, port: this.cihazPort, sifre: this.cihazSifre === '' ? '0' : this.cihazSifre }
      this.axios.post(`${this.$store.state.kantin.ytUrl}controldevice`, payload)
        .then(resp => {
          if ('result' in resp.data) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Sonuç',
                icon: 'BellIcon',
                text: resp.data.result,
                variant: 'danger',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Sonuç',
                icon: 'BellIcon',
                text: 'Başarılı',
                variant: 'success',
              },
            })
            this.cihazId = resp.data.result_data.deviceId
            this.degisken = true
          }
          this.test = false
        })
        .catch(err => {
          // eslint-disable-next-line no-unused-vars
          const s = err
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Uyarı',
              icon: 'BellIcon',
              text: 'Bir hata oluştu. Lütfen tekrar deneyiniz.',
              variant: 'danger',
            },
          })
          this.test = false
        })
    },
    tenfeiKayit() {
      if (this.cihazAdi.length < 3) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Uyarı',
            icon: 'BellIcon',
            text: 'Lütfen cihaz adını en az 3 harf olarak giriniz.',
            variant: 'danger',
          },
        })
      } else {
        this.kayit = true
        const payload = {
          id: this.cihazSId,
          ip: this.cihazIp,
          port: this.cihazPort,
          sifre: this.cihazSifre === '' ? 0 : this.cihazSifre,
          cihazId: this.cihazId,
          cihazAdi: this.cihazAdi,
          sisId: this.sistemSecili.value !== undefined ? this.sistemSecili.value : 1,
          kullaniciId: this.$store.state.userInfo.userData.kOkulId,
          cihazTipi: this.ckSecili.value,
        }
        this.axios.post(`${this.$store.state.kantin.ytUrl}tenfeiregister`, payload)
          .then(resp => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Durum',
                icon: 'BellIcon',
                text: resp.data.result,
                variant: 'success',
              },
            })
            if (resp.data.result === 'Başarılı') {
              this.sifirla()
              this.$emit('tabloGetir')
              this.$refs.cihazKaydet.hide()
            }
            this.kayit = false
          })
        // eslint-disable-next-line no-unused-vars
          .catch(err => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Uyarı',
                icon: 'BellIcon',
                text: 'Bir hata oluştu. Lütfen tekrar deneyiniz.',
                variant: 'danger',
              },
            })
            this.kayit = false
          })
      }
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-slider.scss';
</style>
